import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/es5/locale/fr'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#000',
            secondary: '#000',
          },
        },
        options: { customProperties: true },
      },
      lang: {
        locales: { fr, en },
        current: navigator.language.substr(0, 2),
      },
});
