import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import axios from 'axios';
import VueAxios from 'vue-axios';
import i18n from './i18n';
import moment from 'moment'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

moment.locale(navigator.language);
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
  vuetify,
});

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
