<template>
  <v-app :style="`font-size: 14px;font-family: ${settings && settings.font ? settings.font : 'Lato'} , sans-serif;`">
    <v-dialog
        v-model="showPhoneConfirm"
        class="rounded-0 dialog-hide-border"
        persistent
      >
        <v-card tile outlined style="border:none">
          <v-card-title>
            {{ $t('sms_confirm_title') }}
          </v-card-title>
          <v-text-field
            v-model="smsConfirm"
            :label="$t('sms_confirm')"
            color="primary"
            :rules="rules"
            @update:error="checkSms = $event"
            hide-details="auto"
            class="mx-4 pt-6 mb-11"
            clearable
          ></v-text-field>
          <v-btn
            v-if="!error.showError"
            :disabled="checkSms"
            depressed 
            tile 
            x-large 
            width="100%"
            class="white--text"
            color="primary" 
            @click="confirmSmsCode()"
            :loading="loadSmsCode"
          >
            {{ $t('confirm_sms_code') }}
          </v-btn>
          <v-alert
            class="ma-0"
            :style="buttonFixed ? 'position:fixed;bottom:0' : null"
            :value="error.showError"
            rounded="0"
            type="error"
            width="100%"
          >
            {{ error.textError }}
          </v-alert>
        </v-card>
      </v-dialog>
    <v-app-bar class="ma-0" dense app flat color="#fff">
      <v-container class="px-0 align-center d-flex flex-column">
        <v-img
          v-if="settings && settings.logo"
          :src="settings.logo"
          max-width="110px"
          height="auto"
        ></v-img>
      </v-container>
      <v-btn icon v-if="isIframe" @click="closeIframe">
          <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main class="pt-11">
      <v-container fluid class="pa-0 pb-12 d-flex flex-column justify-center" style="max-width:1200px">
        <v-img
          v-if="settings && settings.banner"
          :src="getTrad(settings.banner)"
        >
          <div class="py-6 px-8 font-weight-black" :style="settings && settings.banner_txt_color ? `color:${settings.banner_txt_color}` : 'color: #fff'">
            <div v-if="settings.banner_txt_1" style="font-size: 2.7vmin">{{ getTrad(settings.banner_txt_1) }}</div>
            <div v-if="settings.banner_txt_2" style="font-size: 6vmin">{{ getTrad(settings.banner_txt_2) }}</div>
            <div v-if="settings.banner_txt_3" style="font-size: 4vmin">{{ getTrad(settings.banner_txt_3) }}</div>
            <div v-if="settings.banner_txt_4" class="d-none d-sm-none d-md-block d-lg-block d-xl-block" style="font-size: 2.3vmin">{{ getTrad(settings.banner_txt_4) }}</div>
          </div>
        </v-img>
        <p v-html="getTrad(settings.main_txt)" v-if="$route.name === 'CreateAppointment' && settings && settings.main_txt" class="my-5 px-3 align-self-center" style="text-align: justify"></p>
        <keep-alive>
          <router-view @next="nextStep($event)" @create="createAppointment($event)" :loadAppointment="loadPostAppointment" @error="setErrorDisplay($event.error, $event.message)" :buttonFixed="buttonFixed" :settings="settings" :default="defaultData" :displayError="error" @reset="resetData" :showPhoneConfirm="showPhoneConfirm" :getTrad="getTrad"></router-view>
        </keep-alive>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import moment from 'moment'

export default {
  name: 'App',
  data () {
    return {
      firstStepData: null,
      createdAppointmentId: null,
      showPhoneConfirm: false,
      loadPostAppointment: false,
      loadSmsCode: false,
      settings: null,
      rules: [
        value => !!value || this.$t('required')
      ],
      smsConfirm: null,
      checkSms: true,
      error: {
        showError: false,
        textError: undefined
      },
      textError: undefined,
      isIframe: (this.$route.query.iframe == "true") ? true: false,
      defaultData: {}
    }
  },
  async mounted() {
    let settings = null;
    try {
        settings = await this.$http.get('/appointments/settings')
        this.settings = settings.data
        this.$vuetify.theme.themes.light.primary = settings.data.primary_color;
        document.title = settings.data.partner_name;
      } catch(err) {
        this.setErrorDisplay(err.response.data, err.message);
      }
    if(this.$route.query.local) {
      moment.locale(this.$route.query.local.substr(0,2));
      this.$i18n.locale = this.$route.query.local.substr(0,2);
    }
    if(this.$route.query.customer_last_name) {
      this.defaultData.lastName = this.$route.query.customer_last_name
    }
    if(this.$route.query.customer_first_name) {
      this.defaultData.firstName = this.$route.query.customer_first_name
    }
    if(this.$route.query.customer_email) {
      this.defaultData.email = this.$route.query.customer_email
    }
    if(this.$route.query.customer_phone) {
      this.defaultData.phone = this.$route.query.customer_phone
    }
    if(this.$route.query.store_id) {
      this.defaultData.store_id = this.$route.query.store_id
    }
  },
  computed: {
    buttonFixed () {
      if (this.isIframe) {
        return true
      }
      const buttonFixed = {xs:'x-small',sm:'small'}[this.$vuetify.breakpoint.name];
      return buttonFixed ? true : false
    }
  },
  methods: {
    getTrad(trads) {
      if(trads[this.$i18n.locale]) {
        return trads[this.$i18n.locale]
      }
      return trads['en'];
    },
    nextStep(value) {
      this.firstStepData = value;
      this.$router.push({ name: 'PersonalDetails'})
    },
    async createAppointment(secondStepData) {
      this.loadPostAppointment = true;
      let appointment = null;
      try {
        appointment = await this.$http.post('/appointments', {
          date_begin: this.firstStepData.selectedDay.begin,
          date_end: this.firstStepData.selectedDay.end,
          location_id: this.firstStepData.store.location_id,
          service_name:  this.firstStepData.service.service_name,
          first_name: secondStepData.firstName,
          last_name: secondStepData.lastName,
          email: secondStepData.email,
          phone: secondStepData.phoneData.number.e164,
          note: secondStepData.note,
          lang: navigator.language
          }
        );
        if(this.settings.sms_confirmation) {
          this.showPhoneConfirm = true;
        } else {
          this.$router.push({ name: 'ConfirmationAppointment'})
        }
        this.createdAppointmentId = appointment.data
      } catch(err) {
        this.setErrorDisplay(err.response.data, err.message);
        this.loadPostAppointment = false;
        throw err;
      }
      this.loadPostAppointment = false;
    },
    async confirmSmsCode() {
      this.loadSmsCode = true;
      try{
        await this.$http.patch(`/appointments/${this.createdAppointmentId}`, {
            current_status: "waiting_seller_confirmation",
            confirm_code: this.smsConfirm
          }
        );
      } catch(err) {
        this.setErrorDisplay(err.response.data, err.message);
        this.loadSmsCode = false;
        throw err;
      }
      this.showPhoneConfirm = false;
      this.loadSmsCode = false;
      this.$router.push({ name: 'ConfirmationAppointment'})
    },
    setErrorDisplay(error, message) {
      // Check if translation exist
      if(error && this.$te(`errors.${error}`)) {
        this.error.textError = this.$t(`errors.${error}`);
      } else {
        this.error.textError = message;
      }
      this.error.showError = true;    
      setTimeout(function () { this.clearError() }.bind(this), 4000)
    },
    clearError() {
      this.error.textError = undefined;
      this.error.showError = false;
    },
    closeIframe() {
      window.parent.postMessage('closeIframe', '*')
    },
    resetData() {
      this.smsConfirm = ''
    }
  },
}
</script>
<style>
@font-face {
    font-family: 'GillSansMTPro';
    src: url("assets/fonts/GillSansMTPro-Light.otf");
}
@font-face {
  font-family: 'gitan';
  src: url('https://d1vyvvdcob5l6a.cloudfront.net/fonts/GitanLatnWeb-Me.woff') format('woff'),
  url('https://d1vyvvdcob5l6a.cloudfront.net/fonts/GitanLatnWeb-Me.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}
</style>