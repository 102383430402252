<template>
    <div>
      <div class="d-flex align-center flex-column px-3">
        <v-card v-for="(card, index) in cards" :key="card" class="mb-6" color="#f0f0f0" flat width="100%" max-width="900px" :height="card === '1' || card === '2' ? '81px' : '150px'">
            <v-container class="flex-row d-flex align-center">
              <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block font-weight-bold mb-7 mr-3" style="color: var(--v-primary-base);font-size: 30px">{{card}}</div>
              <v-select
                v-if='index === 0'
                v-model="result.service"
                :items="servicesFormated"
                color="#000"
                background-color="#fff"
                filled
                :label="getTitle(index)"
                @input="setService"
                :loading="loadingServices"
              >
                <template slot="item" slot-scope="data">
                  <v-icon class="mr-1" v-if="data.item.value.videoconference">mdi-video</v-icon><span>{{data.item.text}}</span>
                </template>
              </v-select>
              <v-autocomplete
                v-if='index === 1'
                :items="storesFormated"
                color="#000"
                background-color="#fff"
                filled
                solo
                v-model="result.store"
                flat
                :disabled="arrayEmpty(stores)"
                :label="getTitle(index)"
                @input="setStore"
                :loading="loadingStores"
              ></v-autocomplete>
              <div v-if='index === 2' class="flex-column" style="width:100%">
                <v-dialog
                  ref="dialog"
                  v-model="menu2"
                  :return-value.sync="date"
                  transition="scale-transition"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatDate"
                      :label="getTitle(index)"
                      :disabled="arrayEmpty(days)"
                      prepend-icon="mdi-calendar"
                      :loading="loadingDays"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="pt-3 mt-0"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :allowed-dates="getAllowedDates"
                    :min="datePickerFormat"
                    no-title
                    locale="fr"
                    color="primary"
                    first-day-of-week="1"
                    v-model="result.selectedDate"
                    @input="menu2 = false"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu2 = false"
                    >
                     {{ $t('cancel') }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-select
                  :disabled="arrayEmpty(getHoursByDate)"
                  :items="getHoursByDate"
                  :label="getTitle(3)"
                  color="#000"
                  v-model="result.selectedDay"
                  background-color="#fff"
                  filled
                ></v-select>
              </div>
            </v-container>
        </v-card>
        </div>
        <v-row class="row-button justify-center">
          <v-btn v-if="!displayError.showError" :fixed="buttonFixed" bottom :disabled="!result.selectedDay" @click="$emit('next', result)" depressed tile x-large width="100%" color="primary" class="ma-0 white--text" :style="!result.selectedDay ? 'background-color:rgb(214, 214, 214) !important;cursor:pointer !important;bottom:0' : 'cursor:pointer !important;bottom:0'">
            {{ $t('button_continue') }}
          </v-btn>
          <v-alert
            class="ma-0"
            :style="buttonFixed ? 'position:fixed;bottom:0' : null"
            rounded="0"
            :value="displayError.showError"
            type="error"
            width="100%"
          >
            {{ displayError.textError }}
          </v-alert>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CreateAppointment',
  props: {
    reset: {
      default: false,
      type: Boolean,
    },
    buttonFixed: {
      default: false,
      type: Boolean,
    },
    default: {
      default: () => {},
      type: Object,
    },
    displayError: {
      type: Object,
      default: () => {
        return { showError: false,textError: undefined }
      },
    },
  },
  data () {
    return {
      cards: [
        '1',
        '2',
        '3'
      ],
      services: [],
      stores: [],
      days: [],
      result: {
        service: {},
        store: {},
        selectedDate: undefined,
        selectedDay: undefined
      },
      loadingServices: false,
      loadingStores: false,
      loadingDays: false,
      menu2: false,
      date: false,
    }
  },
  activated() {
    if(this.reset) {
      this.$emit('reset')
      this.days = [];
      this.services = [];
      this.stores = [];
      this.result = {
        service:{},
        store: {},
        selectedDate: undefined,
        selectedDay: undefined
      }
      this.getServices()
    }
  },
  computed: {
    servicesFormated () {
      let services = []
      for (const service of this.services) {
        let serviceName = service.service_name
        if (typeof service.service_name === 'object') {
          serviceName = service.service_name[this.$root.$i18n.locale] || service.service_name[Object.keys(service.service_name)[0]]
        }

        services.push(
          {text: `${service.duration / 60} min - ${serviceName}`, value: service}
        )
      }
      return services
    },
    storesFormated () {
      let stores = []
      for (const store of this.stores) {
        stores.push(
          {text: `${store.address.postal_code} - ${store.name}`, value: store}
        )
      }
      return stores
    },
    datePickerFormat () {
      return moment().format('YYYY-MM-DD')
    },
    getHoursByDate() {
      for (const day of this.days) {
        if(moment(day.date).format('YYYY-MM-DD') === this.result.selectedDate) {
          return this.formatHours(day.slots);
        }
      }
      return []
    },
    formatDate() {
      if(this.result.selectedDate){
        return moment(this.result.selectedDate).format("L")
      }
      return undefined
    }
  },
  mounted () {
    this.getServices()
  },
  methods: {
    async getServices () {
      this.loadingServices = true
      let getServices = null
      try {
        getServices = await this.$http.get('/appointments/services', { params: { enabled: true } })
        this.services = getServices.data
      } catch(err) {
        this.$emit('error', {error: err.response.data, message: err.message});
        this.loadingServices = false
        throw err;
      }
      this.loadingServices = false
    },
    setService (service) {
      this.days = [];
      this.result.selectedDate = undefined;
      this.result.selectedDay = undefined;
      this.setStores(service._id)
    },
    async setStores (id) {
      this.loadingStores = true
      let stores =  null
      try {
        stores = await this.$http.get('/appointments/services/' + id + '/stores')
      } catch(err) {
        this.$emit('error', {error: err.response.data, message: err.message});
        this.loadingStores = false
        throw err;
      }
      this.stores = stores.data.sort((store1, store2) => {
        if(store1.address.postal_code && store2.address.postal_code) {
          return store1.address.postal_code - store2.address.postal_code
        }
        return null
      })
      this.setSelectedStore(stores.data);
      this.loadingStores = false
    },
    setSelectedStore(stores) {
      if(this.default.store_id) {
        for (const store of stores) {
          if(store.location_id === this.default.store_id) {
            this.result.store = store;
          }
        }
      } else {
        this.result.store = {};
      }
    },
    setStore (store) {
      this.result.selectedDate = undefined;
      this.result.selectedDay = undefined;
      this.setDays(store.location_id)
    },
    async setDays (locationId) {
      this.loadingDays = true;
      let days = null
      try {
        days  = await this.$http.get('/appointments/services/' + this.result.service._id + '/stores/' + locationId + '/planning')
      } catch(err) {
        this.$emit('error', {error: err.response.data, message: err.message});
        this.loadingDays = false;
        throw err;
      }
      this.days = days.data;
      this.loadingDays = false;
    },
    getTitle (index) {
      switch (index) {
        case 0:
          return this.$t('title_service')
        case 1:
          return this.$t('title_shop')
        case 2:
          return this.$t('title_day')
        case 3:
          return this.$t('title_hours')
        default:
          break
      }
    },
    formatHours(slots) {
      let hoursArray = [];
      for (const slot of slots) {
        if(slot.bookable) {
          if(navigator.language.substr(0, 2) === 'fr') {
            hoursArray.push({ text: `${moment(slot.begin).format('HH:mm')} - ${moment(slot.end).format('HH:mm')}`, value: slot})
          } else {
            hoursArray.push({ text: `${moment(slot.begin).format('hh:mm')} - ${moment(slot.end).format('hh:mm')}`, value: slot})
          }
        }
      }
      return hoursArray.sort();
      
    },
    arrayEmpty(array) {
      return typeof array === 'undefined' || array.length <= 0;
    },
    getAllowedDates(date) {
      for (const day of this.days) {
        if(!this.arrayEmpty(day.slots) && this.checkBookable(day.slots) && moment(date).format('YYYY-MM-DD') === moment(day.date).format('YYYY-MM-DD')) {
          return true;
        }
      }
      return false;
    },
    checkBookable(slots) {
      for (const slot of slots) {
        if(slot.bookable) {
          return true;
        }
      }
      return false;
    },
  }
}
</script>
