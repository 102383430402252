import Vue from 'vue';
import VueRouter from 'vue-router';
import CreateAppointment from '../views/CreateAppointment.vue';
import PersonalDetails from '../views/PersonalDetails.vue';
import ConfirmationAppointment from '../views/ConfirmationAppointment.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'CreateAppointment',
    component: CreateAppointment,
    props: true
  },
  {
    path: '/details',
    name: 'PersonalDetails',
    component: PersonalDetails
  },
  {
    path: '/confirmation',
    name: 'ConfirmationAppointment',
    component: ConfirmationAppointment
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
