var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"d-flex mx-3 mb-6 mt-6 justify-center",attrs:{"color":"#f0f0f0","flat":"","width":"100%","max-width":"900px"}},[_c('div',{staticClass:"d-none d-sm-none d-md-block d-lg-block d-xl-block font-weight-bold align-self-center text-center pl-3 ma-0",staticStyle:{"width":"100%: height:35px","color":"var(--v-primary-base)","font-size":"30px"}},[_vm._v("4")]),_c('div',{staticClass:"pa-4",staticStyle:{"width":"100%"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('name'),"hide-details":"auto","error-messages":errors},on:{"update:error":function($event){_vm.error.lastName = $event}},model:{value:(_vm.inputData.lastName),callback:function ($$v) {_vm.$set(_vm.inputData, "lastName", $$v)},expression:"inputData.lastName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('firstname'),"hide-details":"auto","error-messages":errors},on:{"update:error":function($event){_vm.error.firstName = $event}},model:{value:(_vm.inputData.firstName),callback:function ($$v) {_vm.$set(_vm.inputData, "firstName", $$v)},expression:"inputData.firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('email'),"hide-details":"auto","error-messages":errors},on:{"update:error":function($event){_vm.error.email = $event}},model:{value:(_vm.inputData.email),callback:function ($$v) {_vm.$set(_vm.inputData, "email", $$v)},expression:"inputData.email"}})]}}],null,true)}),_c('vue-tel-input-vuetify',{staticClass:"mt-1",attrs:{"id":"phone_input","label":"","defaultCountry":_vm.getCountry,"placeholder":_vm.$t('phone_number'),"preferredCountries":['FR', 'GB', 'US', 'ES', 'IT', 'NL'],"error-messages":_vm.emailMsgError},on:{"validate":function($event){_vm.inputData.phoneData = $event}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('validation-provider',{attrs:{"rules":"max:480"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"note","filled":"","label":_vm.$t('note'),"auto-grow":"","error-messages":errors,"value":""},on:{"update:error":function($event){_vm.error.note = $event}},model:{value:(_vm.inputData.note),callback:function ($$v) {_vm.$set(_vm.inputData, "note", $$v)},expression:"inputData.note"}})]}}],null,true)})],1)])],1),(_vm.settings && _vm.settings.personal_details_txt)?_c('p',{staticClass:"px-3 mb-9 max-width-container align-self-center",staticStyle:{"text-align":"justify"},domProps:{"innerHTML":_vm._s(_vm.getTrad(_vm.settings.personal_details_txt))}}):_vm._e(),_c('v-btn',{staticClass:"pa-2 px-3 grey--text justify-center d-flex text-decoration-underline",style:(_vm.buttonFixed ? 'cursor:pointer !important;bottom:50px !important' : 'cursor:pointer !important'),attrs:{"fixed":_vm.buttonFixed,"color":"white","bottom":"","depressed":"","tile":"","width":"100%"},on:{"click":function($event){return _vm.$router.push({ name: 'CreateAppointment'})}}},[_vm._v(" "+_vm._s(_vm.$t('button_back'))+" ")]),((!_vm.displayError.showError && !_vm.showPhoneConfirm) || _vm.showPhoneConfirm)?_c('v-btn',{staticClass:"white--text",style:(invalid || !_vm.inputData.phoneData.valid ? 'background-color:rgb(214, 214, 214) !important;cursor:pointer !important;bottom:0' : 'cursor:pointer !important;bottom:0'),attrs:{"fixed":_vm.buttonFixed,"bottom":"","type":"submit","depressed":"","tile":"","x-large":"","width":"100%","color":"primary","loading":_vm.loadAppointment,"disabled":invalid || !_vm.inputData.phoneData.valid}},[_vm._v(" "+_vm._s(_vm.$t('button_create'))+" ")]):_vm._e(),_c('v-alert',{staticClass:"ma-0",style:(_vm.buttonFixed ? 'position:fixed;bottom:0' : null),attrs:{"value":_vm.displayError.showError && !_vm.showPhoneConfirm,"rounded":"0","type":"error","width":"100%"}},[_vm._v(" "+_vm._s(_vm.displayError.textError)+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }