<template>
    <div>
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-form @submit.prevent="submit">
          <div class="d-flex justify-center">
            <v-card class="d-flex mx-3 mb-6 mt-6 justify-center" color="#f0f0f0" flat width="100%" max-width="900px">
              <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block font-weight-bold align-self-center text-center pl-3 ma-0" style="width:100%: height:35px; color: var(--v-primary-base);font-size: 30px">4</div>
              <div class="pa-4" style="width:100%">
                    <validation-provider rules="required|min:2" v-slot="{ errors }">
                      <v-text-field
                          v-model="inputData.lastName"
                          :label="$t('name')"
                          hide-details="auto"
                          @update:error="error.lastName = $event"
                          :error-messages="errors"
                          ></v-text-field>
                    </validation-provider>
                    <validation-provider rules="required|min:2" v-slot="{ errors }">
                      <v-text-field
                          v-model="inputData.firstName"
                          :label="$t('firstname')"
                          hide-details="auto"
                          @update:error="error.firstName = $event"
                          :error-messages="errors"
                          ></v-text-field>
                    </validation-provider>
                    <validation-provider rules="required|email" v-slot="{ errors }">
                      <v-text-field
                          v-model="inputData.email"
                          :label="$t('email')"
                          hide-details="auto"
                          @update:error="error.email = $event"
                          :error-messages="errors"
                          ></v-text-field>
                    </validation-provider>
                    <vue-tel-input-vuetify class="mt-1" id="phone_input" label="" 
                      v-model="phone"
                      :defaultCountry="getCountry" :placeholder="$t('phone_number')"
                      @validate="inputData.phoneData = $event"
                      :preferredCountries="['FR', 'GB', 'US', 'ES', 'IT', 'NL']"
                      :error-messages="emailMsgError">
                    </vue-tel-input-vuetify>
                    <validation-provider rules="max:480" v-slot="{ errors }">
                      <v-textarea
                        name="note"
                        v-model="inputData.note"
                        filled
                        :label="$t('note')"
                        auto-grow
                        :error-messages="errors"
                        @update:error="error.note = $event"
                        value=""
                      ></v-textarea>
                    </validation-provider>
              </div>
            </v-card>
          </div>
          <p v-html="getTrad(settings.personal_details_txt)" v-if="settings && settings.personal_details_txt" class="px-3 mb-9 max-width-container align-self-center" style="text-align: justify"></p>
          <v-btn :fixed="buttonFixed" color="white" bottom depressed tile width="100%" class="pa-2 px-3 grey--text justify-center d-flex text-decoration-underline" @click="$router.push({ name: 'CreateAppointment'})" :style="buttonFixed ? 'cursor:pointer !important;bottom:50px !important' : 'cursor:pointer !important'">
            {{ $t('button_back') }}
          </v-btn>
          <v-btn v-if="(!displayError.showError && !showPhoneConfirm) || showPhoneConfirm" :fixed="buttonFixed" bottom type="submit" depressed tile x-large width="100%" color="primary" class="white--text" :loading="loadAppointment" :disabled="invalid || !inputData.phoneData.valid" :style="invalid || !inputData.phoneData.valid ? 'background-color:rgb(214, 214, 214) !important;cursor:pointer !important;bottom:0' : 'cursor:pointer !important;bottom:0'">
            {{ $t('button_create') }}
          </v-btn>
          <v-alert
            class="ma-0"
            :style="buttonFixed ? 'position:fixed;bottom:0' : null"
            :value="displayError.showError && !showPhoneConfirm"
            rounded="0"
            type="error"
            width="100%"
          >
            {{ displayError.textError }}
          </v-alert>
        </v-form>
      </validation-observer>
    </div>
</template>

<script>
import { required, email, max, min } from 'vee-validate/dist/rules';
import i18n from '../i18n';
import { extend } from 'vee-validate';
import countryList from '../data/country-lang'

extend('required', {
  ...required,
  message: i18n.tc('required')
});
extend('email', {
  ...email,
  message: i18n.tc('email_valid')
});
extend('max', {
  ...max,
  message: i18n.tc('max_characters')
});
extend('min', {
  ...min,
  message: i18n.tc('min_characters')
})

export default {
  name: 'PersonalDetails',
  props: {
    displayError: {
      type: Object,
      default: () => {
        return { showError: false,textError: undefined }
      },
    },
    loadAppointment: {
      default: false,
      type: Boolean,
    },
    settings: {
      type: Object
    },
    default: {
      type: Object
    },
    showPhoneConfirm: {
      default: false,
      type: Boolean,
    },
    buttonFixed: {
      default: false,
      type: Boolean,
    },
    getTrad: {
      type: Function,
    }
  },
  data () {
    return {
      phone: null,
      inputData: {
        lastName: null,
        firstName: null,
        email: null,
        phoneData: Object,
        note: null
      },
      error: {
        firstName: true,
        lastName: true,
        email: true,
        note: false,
      },
    }
  },
  computed: {
    emailMsgError() {
      if(!this.inputData.phoneData.isValid && this.phone) {
        return this.$t('phone_valid');
      }
      return []
    },
    getCountry() {
      for (const country of countryList) {
        if(navigator.language.toUpperCase() === country.lang.toUpperCase()) {
          if(country.territory === "") {
            return country.lang.toUpperCase();
          } else {
            return country.territory;
          }
        }
      }
      return 'EN';
    }
  },
  mounted() {
    if(this.default) {
      if(this.default.lastName) {
        this.inputData.lastName = this.default.lastName
      }
      if(this.default.firstName) {
        this.inputData.firstName = this.default.firstName
      }
      if(this.default.email) {
        this.inputData.email = this.default.email
      }
      if(this.default.phone) {
        this.phone = this.default.phone
      }
    }
  },
  methods: {
    countrySelected (val) {
      this.countryCode = val.dialCode
    },
    submit () {
      this.$refs.form.validate().then(success => {
        if(success && this.inputData.phoneData.valid){
          this.$emit('create', this.inputData)
        }
      });
    },
  }
}
</script>
