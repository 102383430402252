<template>
    <div>
        <div v-html="getTrad(settings.validation_txt_1)" style="font-size: 16px;" class="text-center mt-5"></div>
        <div v-html="getTrad(settings.validation_txt_2)" style="font-size: 16px;" class="text-center mt-5"></div>
        <v-btn @click="$router.push({ name: 'CreateAppointment', params: { reset: true }})" :fixed="buttonFixed" bottom class="white--text align-center mt-5" style="cursor:pointer !important;bottom:0" width="100%" depressed tile x-large color="primary">
            {{ $t('back_create') }}
        </v-btn>
    </div>
</template>

<script>

export default {
  name: 'ConfirmationAppointment',
  props: {
    buttonFixed: {
      default: false,
      type: Boolean,
    },
    settings: {
      type: Object
    },
    getTrad: {
      type: Function,
    }
  },
}
</script>
